import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../constant/schema.js";
import { loginCall } from "../slice.js";
import { toast } from "react-toastify";
import Loader from "../../component/Loader"

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    resolver: yupResolver(loginSchema),
    shouldFocusError: true,
  });

  useEffect(() => {
    localStorage.removeItem('token_t');
  }, []);

  const onSubmit = (data) => {
    setSubmitting(true);
    dispatch(
      loginCall({
        email: data?.email?.trim()?.toLowerCase(),
        password: data?.password,
        user_type: "teacher",
      })
    ).then(async (res) => {
      setSubmitting(false);

      await localStorage.setItem("token_t", res?.payload?.data?.token);
      await localStorage.setItem(
        "user",
        JSON.stringify(res?.payload?.data?.user)
      );
      if (res?.payload?.code === 1) {
        navigate("/appointments", { replace: true });
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  return (
    <section className="bg-image">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="logo-bg position-relative">
              <p>
                <img
                  src="assets/img/headerlogo.png"
                  className="logo-icon"
                  alt="logo"
                />
              </p>
              <p className="d-none d-md-block">
                <img
                  src="assets/img/banner_logo.png"
                  className="logo-img"
                  alt="logo"
                />
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mt-5">
            <div className="form-style mt-5">
              <form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <h2 className="mdl-ttl">Teacher Log in</h2>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Email</label>
                  <div className="input-container">
                    <input
                      type="email"
                      className="form-control ps-4"
                      placeholder="Email"
                      name="email"
                      {...register("email")}
                    />
                    <img
                      src="assets/img/email.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors?.email && (
                    <div className="text-red">{errors?.email?.message}</div>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Password</label>

                  <div className="input-container">
                    <input
                      id="password-field"
                      type={toggle ? "text" : "password"}
                      className="form-control ps-4"
                      placeholder="Password"
                      name="password"
                      {...register("password")}
                    />
                    <img
                      src="assets/img/password.png"
                      className="input-img pass"
                      alt=""
                    />
                    <span
                      onClick={() => {
                        setToggle(!toggle);
                      }}
                      className="pass-view field-icon toggle-password cursor-pointer"
                    ></span>
                  </div>
                  {errors?.password && (
                    <div className="text-red">{errors?.password?.message}</div>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                  <div className="input-container">
                    <p>
                      <Link
                        to="/forget-password"
                        className="theme-color fw-bold"
                      >
                        Forgot Password?
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center">
                  <button type="submit" className="btn btn-submit btn-animated" disabled={submitting}>
                    {submitting ? <Loader /> : "Log In"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
