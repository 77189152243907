import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Loader from "../../component/Loader";
import { Modal } from "react-bootstrap";
import { FetchStudentDetail, getstudentDetail } from "../class/ClassSlice";

function Student() {
  const { id } = useParams();
  // const data = useSelector(getstudentDetail);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(null);
  const [isDebt ,  setisDebt] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(FetchStudentDetail(id)).then((res) => {
        setLoading(false);
        if (res?.payload.code === 1) {
          setData(res?.payload?.data?.student_details);
          setAddress(res?.payload?.data?.parent_address_details);
        }
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    setisDebt(Number(data?.parent_details_with_consent?.wallet) < 0);
  }, [data]);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <section className="sec-bg py-4 px-3 bottom-space">
        <div className="container-fluid">
          <div className="row align-items-center"></div>

          <div className="row mt-3 px-md-5">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <div className="accordion accordion-flush" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <h3>Account Holder Details</h3>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                          <div className=" px-5 py-3 text-capitalize position-relative h-100">
                            <p className="mb-1">full name</p>
                            <h6 className="">
                              <img
                                src="assets/images/First-Name.png"
                                className="detail-icon"
                                alt=""
                              />
                              {data?.parent_details_with_consent?.first_name
                                ? data?.parent_details_with_consent
                                    ?.first_name +
                                  " " +
                                  data?.parent_details_with_consent?.last_name
                                : ""}
                            </h6>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                          <div className=" px-5 py-3 text-capitalize position-relative h-100">
                            <p className="mb-1">username</p>
                            <h6 className="">
                              <img
                                src="assets/images/First-Name.png"
                                className="detail-icon"
                                alt=""
                              />
                              {data?.parent_details_with_consent?.username ||
                                "NA"}
                            </h6>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                          <div className=" px-5 py-3 text-capitalize position-relative h-100">
                            <p className="mb-1">email ID</p>
                            <h6 className="text-break">
                              <img
                                src="assets/images/Email-Address.png"
                                className="detail-icon"
                                alt=""
                              />
                              {data?.parent_details_with_consent?.email || "NA"}
                            </h6>
                          </div>
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                          <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                            <p className="mb-1">full address</p>
                            <h6 className="">
                              <img
                                src="assets/images/address.png"
                                className="detail-icon"
                                alt=""
                              />
                              {address
                                ? `${address?.property_number}, ${
                                    address?.property_name
                                      ? address?.property_name + ", "
                                      : ", "
                                  } ${address?.street_number}, ${
                                    address?.town
                                  }, ${address?.country}-${
                                    address?.postal_code
                                  }`
                                : "NA"}
                            </h6>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                          <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                            <p className="mb-1">contact number</p>
                            <h6 className="">
                              <img
                                src="assets/images/mobile.png"
                                className="detail-icon"
                                alt=""
                              />{" "}
                              {address?.mobile
                                ? `${address?.countryCode}${address?.mobile}`
                                : "NA"}
                            </h6>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                          <div
                            className="bg-white px-5 py-3  position-relative h-100"
                            style={{
                              textTransform: "uppercase",
                            }}
                          >
                            <h5 className="mb-1">
                              {data?.parent_details_with_consent?.consent
                                ?.length > 1
                                ? "Consents"
                                : "Consent"}
                            </h5>
                            {data?.parent_details_with_consent?.consent
                              ?.length > 0
                              ? data?.parent_details_with_consent?.consent?.map(
                                  (item, i) => {
                                    return (
                                      <>
                                        <p>
                                          <span>
                                            {item?.consent_details?.label}:{" "}
                                          </span>
                                          <span className="fw-bold">
                                            {item?.value}
                                          </span>
                                        </p>
                                        {item?.consent_details?.id === 6 && (
                                          <p>
                                            <span>Medication: </span>
                                            <span className="fw-bold">
                                              {item?.note}
                                            </span>
                                          </p>
                                        )}
                                      </>
                                    );
                                  }
                                )
                              : "No Consent Added."}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-sm-12 mb-3">
              <h3>Account Holder Details</h3>
            </div> */}

            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <hr />
            </div>

            <div className="col-sm-12 mb-3">
              <h3>Student Details</h3>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">full name</p>
                  <h6 className="">
                    <img
                      src="assets/images/First-Name.png"
                      className="detail-icon"
                      alt=""
                    />
                    {data?.first_name
                      ? data?.first_name + " " + data?.last_name
                      : "NA"}
                  </h6>
                </div>
              </div>

              {/* <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                      <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                        <p className="mb-1">full address</p>
                        <h6 className="">
                          <img
                            src="assets/images/address.png"
                            className="detail-icon"
                            alt=""
                          />
                          {data?.address || "NA"}
                        </h6>
                      </div>
                    </div> */}
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">membership level</p>
                  <h6 className="">
                    <img
                      src="assets/images/membership-status.png"
                      className="detail-icon"
                      alt=""
                    />
                    {data?.student_type || "NA"}
                  </h6>
                </div>
              </div>

              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">data of birth</p>
                  <h6 className="">
                    <img
                      src="assets/images/Date-of-Birth.png"
                      className="detail-icon"
                      alt=""
                    />
                    {data?.dob && data?.dob !== "0000-00-00"
                      ? moment(data?.dob).format("DD MMM YYYY")
                      : data?.dob_year
                      ? moment(
                          `${data?.dob_year}-${data?.dob_month}-${data?.dob_day}`
                        ).format("DD MMM YYYY")
                      : "NA"}
                  </h6>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">discipline</p>
                  <h6 className="">
                    <img
                      src="assets/images/discipline.png"
                      className="detail-icon"
                      alt=""
                    />
                    {data?.discipline_details
                      ? data?.discipline_details?.name
                      : "NA"}
                  </h6>
                </div>
              </div>

              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">Emergency Contact Number</p>
                  <h6 className="">
                    <img
                      src="assets/images/address.png"
                      className="detail-icon"
                      alt=""
                    />
                    {data?.emergency_contactNo &&
                    data?.emergency_contactNo !== "null" &&
                    data?.emergency_contactNo !== "undefined"
                      ? data?.emergency_countryCode + data?.emergency_contactNo
                      : "NA"}
                  </h6>
                </div>
              </div>
              {data?.membership_details && (
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                  <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                    <p className="mb-1">next renewal date</p>
                    <h6 className="">
                      <img
                        src="assets/images/membership-status.png"
                        className="detail-icon"
                        alt=""
                      />
                      {moment(data?.membership_details?.valid_till).format(
                        "DD MMM YYYY"
                      )}
                    </h6>
                  </div>
                </div>
              )}
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">school venue</p>
                  <h6 className="">
                    <img
                      src="assets/images/address.png"
                      className="detail-icon"
                      alt=""
                    />
                    {data?.school_details
                      ? `${data?.school_details?.name}, ${data?.school_details?.areacode_details?.areacode}`
                      : ""}
                  </h6>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                <div className="bg-white px-5 py-3 text-capitalize position-relative h-100">
                  <p className="mb-1">medical note</p>
                  <h6 className="">
                    <img
                      src="assets/images/medical.png"
                      className="detail-icon"
                      alt=""
                    />
                    {data?.medical_note || "NA"}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={isDebt} onHide={() => { setisDebt(false); }} size="md" centered >
        <Modal.Body>
        <center>This user has an outstanding debt!!</center>
        </Modal.Body>
        </Modal>
      </section>
    );
  }
}

export default Student;
