import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import Select from "react-select";
import { useToggle } from "../../utils";
import {
  changeLevel,
  changeRegisterFor,
  FetchAllOtherStudents,
  FetchAllOtherStudentsForEvent,
  FetchRegisterDetail,
  FetchStudentList,
  registerDetail,
} from "../class/ClassSlice";
import {
  addStudentInClass,
  addStudentInEvent,
  cancelClass,
  saveClassAttendance,
  saveEventAttendance,
} from "../../api/class";
import Loader from "../../component/Loader";
import BackButton from "./../../component/Modal/BackButton";
import PrintComponent from "../../component/Modal/PrintComponent";
import PrintEventComponent from "../../component/Modal/PrintEventComponent";
import AddNewStudent from "../../component/Modal/AddStudent";
import ChangeStudentClassComponent from "../../component/Modal/ChangeStudentClass";
import { postFormData } from "../../api";
import Cancel from "./../../component/Modal/Cancel";
import { BiSort } from "react-icons/bi";
const style = {
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    borderWidth: 0,

    "&:hover": {
      borderColor: "transparent",
      outline: "0",
      boxShadow: "none",
    },
  }),
  container: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    backgroundImage: "none",
    paddingRight: "2px",
    borderRadius: "10px",
  }),
};

function RegisterDetail() {
  const navigate = useNavigate();
  const { id, start_time, end_time, dateFor, time_slot_id } = useParams();
  const data = useSelector((state) => state.class.classDetail);
  const [changeClassForStudentData, setChangeClassForStudentData] =
    useState(null);

  const [show, toggle] = useToggle(false);
  const dispatch = useDispatch();
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const [loading, setLoading] = useState(false);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);

  const [tab, setTab] = useState("all");
  const register_for = useSelector((state) => state.class.register_for);
  const levelId = useSelector((state) => state.class.level);
  const [showCancelClassModal, setShowCancelClassModal] = useState(false);

  const [showClass, handleShow] = useToggle(false);
  const [addStudentSubmitting, setAddStudentSubmitting] = useState(false);
  const [changeClassSubmitting, setChangeClassSubmitting] = useState(false);
  const [cancelClassSubmitting, setCancelClassSubmitting] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "first_name",
    direction: "ascending",
  });

  useEffect(() => {
    if (id && start_time) {
      // console.log(start_time, end_time, id, "id inside register");
      setTime({
        start_time: start_time,
        end_time: end_time ? end_time : "",
      });
      setDate({ time_slot_id, date: dateFor });
    } else {
      setDate({ time_slot_id, date: dateFor });
    }
  }, [start_time, end_time, id, dateFor, time_slot_id]);

  const getDetails = useCallback(() => {
    setLoading(true);
    dispatch(
      FetchRegisterDetail({
        classId: id,
        eventId: "",
        register_for: register_for,
        date: date?.date,
        time: "",
        start_time: time?.start_time,
        end_time: time?.end_time,
        levelId: "",
      })
    ).then((res) => {
      setLoading(false);
      if (res?.payload?.code === 1) {
        var times = res?.payload?.data?.register_details?.time_slot
          ?.filter((it) => it?.isCancelled === false)
          ?.map((value) => {
            return {
              available_seats: value?.available_seats,
              start_time: value?.start_time,
              end_time: value?.end_time,
              time_slot_id: value?.time_slot_id,
            };
          })
          ?.filter(
            (value, index, _arr) =>
              _arr.findIndex(
                (it) =>
                  it?.start_time === value.start_time &&
                  it?.end_time === value.end_time &&
                  it?.time_slot_id === value?.time_slot_id
              ) === index
          )
          .sort(function (a, b) {
            return (
              new Date(a.start_time) - new Date(b.start_time) &&
              new Date(a.end_time) - new Date(b.end_time)
            );
          });

        var days = res?.payload?.data?.register_details?.time_slot
          ?.filter((it) => it?.isCancelled === false)
          ?.map((value) => {
            return { ...value, date: value.date };
          })
          ?.filter(
            (value, index, _arr) =>
              _arr.findIndex(
                (it) => it?.date?.toString() === value?.date?.toString()
              ) === index
          )
          ?.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
          });
        setAvailableTimeSlots(times);
        setAvailableDates(days);

        if (!date.date && !dateFor) {
          setDate((prev) => {
            if (prev?.date) {
              return prev;
            } else {
              return {
                ...prev,
                date: days?.find((it) => moment(it?.date).diff(moment()) > 0),
              };
            }
          });
        }
        if (!start_time && !time.start_time) {
          setTime((prev) => {
            return {
              ...prev,
              start_time: times?.[0]?.start_time,
              end_time: times?.[0]?.end_time,
            };
          });
        }
      }
    });
  }, [dispatch, id, date, time, register_for, dateFor, start_time]);

  useEffect(() => {
    if (date?.date) {
      getDetails();
    }
  }, [getDetails, date]);

  const getStudentListing = useCallback(() => {
    dispatch(FetchStudentList());
  }, [dispatch]);

  useEffect(() => {
    getStudentListing();
  }, [getStudentListing]);

  useEffect(() => {
    return () => {
      dispatch(registerDetail(null));
    };
  }, [dispatch]);
  // working forclass
  const StudentAttendance = ({ dataa, status }) => {
    if (register_for === "class") {
      const payload = {
        studentId: dataa?.id,
        classId: id,
        date: date.date,
        start_time: time?.start_time,
        end_time: time?.end_time,
        status: status,
        time_slot_id: date?.time_slot_id,
      };
      if (date && time) {
        saveClassAttendance(payload).then((res) => {
          if (res?.code === 1) {
            toast.success(res?.message);
            getDetails();
          } else {
            toast.error(res?.message);
          }
        });
      } else {
        toast.error("Kindly select a date and time.");
      }
    }
  };

  // const fetchDetails = useCallback(() => {
  //   dispatch(
  //     FetchRegisterDetail({
  //       classId: register_for === "class" ? id : "",
  //       eventId: register_for === "event" ? id : "",
  //       register_for: register_for,
  //       date: date,
  //       start_time: time,
  //       levelId: levelId,
  //     })
  //   );
  // }, [dispatch, date, time, levelId, register_for, id]);

  // useEffect(() => {
  //   fetchDetails();
  // }, [fetchDetails]);

  const addStudentToRegister = (student, _time, _date, _class_type) => {
    // console.log(
    //   data,
    //   _date,
    //   student,
    //   _time,
    //   _class_type,
    //   "Inside add student register"
    // );
    setAddStudentSubmitting(true);
    if (register_for === "class") {
      const payload = {
        userId: student?.userId,
        total_amount:
          student?.student_type?.toLowerCase() === "child"
            ? data?.child_price
            : student?.student_type?.toLowerCase() === "adult"
            ? data?.adult_price
            : 0,
        schoolId: data?.schoolId,
        classId: id,
        teacherId: data?.assigned_teacher?.teacher_details?.id,
        child_price: data?.child_price || 0,
        adult_price: data?.adult_price || 0,
        date: _date.date,
        start_time: _time.start_time,
        end_time: _time.end_time,
        time_slot_for: student?.student_type,
        time_slot_id: _time?.time_slot_id,
        studentId: student?.id,
        student_type: student?.student_type,
        trialAccountStatus: student?.trialAccountStatus,
        addedBy: "TEACHER",
      };
      if (
        _time?.available_seats > 0 &&
        _time?.available_seats <= data?.total_seats
      ) {
        addStudentInClass(payload)
          .then((res) => {
            if (res?.code === 1) {
              if (res?.message?.toLowerCase()?.includes("already added")) {
                toast.error(res?.message);
              } else {
                toast.success(res?.message);
              }
            } else {
              toast.error(res?.message);
            }
            return res;
          })
          .then((response) => {
            if (response?.code === 1) {
              if (
                !response?.message
                  ?.toLowerCase()
                  ?.includes("student already added to the class.")
              ) {
                getDetails();
                toggle();
              } else {
              }
            }
          })
          .then(() => {
            setAddStudentSubmitting(false);
          });
      } else {
        toast.error("Sorry, all the seats in this class are taken.");
        setAddStudentSubmitting(false);
      }
    }
  };

  const handleChangeClass = (data) => {
    setChangeClassSubmitting(true);
    postFormData("class/changeStudentClass", data).then((res) => {
      setChangeClassSubmitting(false);

      if (res?.code === 1) {
        toast.success(res?.message);
        getDetails();
        handleShow();
        setChangeClassForStudentData(null);
      } else {
        toast.error(res?.message);
      }
    });
  };

  const isCancelledClass = useCallback(() => {
    let arr = data?.time_slot?.filter(
      (it) =>
        it?.date === date?.date &&
        it?.start_time === time?.start_time &&
        it?.end_time === time?.end_time
    );
    // console.log(arr?.[0]?.isCancelled);

    return arr?.[0]?.isCancelled;
  }, [data, date?.date, time?.start_time, time?.end_time]);

  const handleCancelClass = (reason) => {
    setCancelClassSubmitting(true);

    let arr = data?.time_slot?.filter((it) => {
      // console.log(it, date, time, "time slot id");

      return (
        it?.date === date?.date &&
        moment(it?.start_time, "HH:mm:ss").format("hh:mm:ss") ===
          moment(time?.start_time, "HH:mm:ss").format("hh:mm:ss") &&
        moment(it?.end_time, "HH:mm:ss").format("hh:mm:ss") ===
          moment(time?.end_time, "HH:mm:ss").format("hh:mm:ss")
      );
    });
    const time_slot_id = arr?.[0]?.time_slot_id;
    // console.log(arr, time_slot_id, "time slot id");

    cancelClass({
      classId: id,
      time_slot_id,
      date: moment(date?.date).format("YYYY-MM-DD"),
      cancel_reason: reason,
    }).then((res) => {
      setCancelClassSubmitting(false);

      if (res?.code === 1) {
        getDetails();
        toast.success(res?.message);
        setShowCancelClassModal(false);
        setDate("");
        setTime("");
      } else {
        toast.error(res?.message);
      }
    });
  };
  // Sorting function
  const sortedData = (students) => {
    if (!students) return [];

    const sortedStudents = [...students];
    sortedStudents.sort((a, b) => {
      const aKey = a[sortConfig.key];
      const bKey = b[sortConfig.key];
      if (typeof aKey === "string" && typeof bKey === "string") {
        return sortConfig.direction === "ascending"
          ? aKey.localeCompare(bKey)
          : bKey.localeCompare(aKey);
      }

      if (aKey < bKey) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (aKey > bKey) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    return sortedStudents;
  };

  // Function to handle column header click
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  if (!data) {
    return (
      <article class="col-lg-9">
        <Loader />
      </article>
    );
  } else {
    return (
      <article class="col-lg-12">
        <div class="article-body store-bd-gy">
          <div class="row align-items-center col-rever">
            <BackButton />

            <div class="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
              <h3>Register Details</h3>
            </div>

            <div className="row detailFIlterIcons">
              <div className="col-sm-12 col-md-7 mt-4 ">
                <div className="changeAndPrint1">
                  <div className=" mb-4">
                    {register_for === "event" && data?.level_details && (
                      <div className="d-flex align-items-center justify-content-between">
                        <label className="theme-color px-2 fw-bold">
                          Levels:{" "}
                        </label>{" "}
                        <select
                          className="form-control w-100"
                          value={levelId}
                          onChange={(e) => {
                            dispatch(changeLevel(e.target.value));
                          }}
                        >
                          <option value="">Select Level</option>
                          {data?.levels?.map((item, i) => (
                            <option key={i} value={item?.id}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                  <div className=" mb-4">
                    <button
                      className="btn btn-view btn-accept"
                      onClick={() => {
                        (async () => {
                          await localStorage.setItem("register_for", "class");
                        })();
                        dispatch(changeRegisterFor("class"));
                        navigate(`/registered-members/${id}/${data?.name}`);
                      }}
                    >
                      All Students
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-5 mt-4">
                <div className="changeAndPrint">
                  <div className="">
                    {" "}
                    <button
                      className="btn btn-view btn-accept"
                      disabled={isCancelledClass === false}
                      onClick={() => {
                        if (isCancelledClass === true) {
                          return;
                        }
                        setShowCancelClassModal(!showCancelClassModal);
                      }}
                    >
                      {isCancelledClass === true
                        ? "Class Cancelled"
                        : "Cancel Class"}
                    </button>
                  </div>
                  <div className="">
                    {" "}
                    {register_for === "class" ? (
                      <PrintComponent data={data} date={date} time={time} />
                    ) : register_for === "event" ? (
                      <PrintEventComponent data={data} />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5 px-md-5">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-5">
              <div class="bg-white p-4 rounded shadow">
                <div class="eventResiterContainer">
                  <div class="">
                    <div class="event-box-flex align-items-normal">
                      <span>
                        <img
                          src={
                            data?.image?.includes("http")
                              ? data?.image
                              : "assets/img/headerlogo.png"
                          }
                          class="event-img"
                          alt=""
                        />
                      </span>
                      <span>
                        <h4>{data?.name || "NA"}</h4>
                        <p className="mb-0">
                          <label className="fw-bold theme-color">
                            Discipline:
                          </label>{" "}
                          {data?.discipline_details?.name}
                        </p>
                        <p className="mb-0">
                          <label className="fw-bold theme-color">
                            Teacher Name:
                          </label>{" "}
                          {register_for === "class"
                            ? data?.assigned_teacher?.teacher_details?.full_name
                            : register_for === "event"
                            ? data?.teacher_details?.full_name
                            : "NA"}
                        </p>
                        {register_for === "event" && data?.level_details ? (
                          <p className="fw-bold theme-color">
                            <label>Level: </label>{" "}
                            <span>{data?.level_details?.name}</span>
                          </p>
                        ) : null}
                        <p class="mb-0 registerInfoGrid">
                          {data?.date && data?.start_time && (
                            <>
                              <span>
                                <img
                                  src="assets/img/Date-of-Birth.png"
                                  class="evt-dtl-icon"
                                  alt=""
                                />{" "}
                                {moment(data?.date).format("DD MMM YYYY")}
                              </span>
                              <span>
                                <img
                                  src="assets/img/time.png"
                                  class="evt-dtl-icon"
                                  alt=""
                                />{" "}
                                {moment(data?.start_time, "hh:mm:ss").format(
                                  "hh:mm a"
                                )}
                              </span>
                            </>
                          )}
                          <span>
                            <img
                              src="assets/img/address.png"
                              class="evt-dtl-icon"
                              alt=""
                            />
                            {data?.school_details
                              ? `${data?.school_details?.name}, ${data?.school_details?.address}`
                              : "NA"}
                          </span>{" "}
                          <span>
                            <img
                              src="assets/img/total-seats.png"
                              class="evt-dtl-icon"
                              alt=""
                            />
                            {register_for === "class"
                              ? data?.total_seats
                              : data?.age_group?.toLowerCase() === "both"
                              ? `Adult | Child`
                              : data?.age_group || "NA"}
                          </span>
                        </p>
                      </span>
                    </div>
                  </div>
                  <div className=" position-relative">
                    <div class="event-price registerEventPrice d-grid reg-evt">
                      <span
                        onClick={toggle}
                        class="btn btn-view mb-3 btn-accept"
                      >
                        Add a New Student in the {register_for}
                      </span>
                      <span class="text-end ">
                        {register_for === "class" ? (
                          <>
                            {data?.adult_price !== "0" && data?.adult_price && (
                              <>
                                {" "}
                                Adult:{" "}
                                <i
                                  class="fa fa-gbp"
                                  aria-hidden="true"
                                ></i>{" "}
                                {data?.adult_price || "NA"}
                              </>
                            )}
                            {data?.child_price !== "0" && data?.child_price && (
                              <>
                                {" "}
                                Child:{" "}
                                <i
                                  class="fa fa-gbp"
                                  aria-hidden="true"
                                ></i>{" "}
                                {data?.child_price}
                              </>
                            )}
                          </>
                        ) : register_for === "event" &&
                          data?.age_group?.toLowerCase() === "both" ? (
                          <>
                            Child: <i class="fa fa-gbp" aria-hidden="true"></i>{" "}
                            {data?.child_price || "NA"} Adult:{" "}
                            <i class="fa fa-gbp" aria-hidden="true"></i>{" "}
                            {data?.adult_price || "NA"}
                          </>
                        ) : register_for === "event" &&
                          data?.age_group?.toLowerCase() === "child" ? (
                          <>
                            <i class="fa fa-gbp" aria-hidden="true"></i>
                            {data?.child_price || "NA"}
                          </>
                        ) : register_for === "event" &&
                          data?.age_group?.toLowerCase() === "adult" ? (
                          <>
                            <i class="fa fa-gbp" aria-hidden="true"></i>{" "}
                            {data?.adult_price || "NA"}
                          </>
                        ) : null}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <div class="row mt-3">
                <div class="col-sm-12 d-flex justify-content-between">
                  <ul
                    class="nav nav-pills group-nav mt-0 mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class={tab === "all" ? "nav-link active" : "nav-link"}
                        onClick={() => {
                          setTab("all");
                        }}
                        id="pills-all-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-all"
                        type="button"
                        role="tab"
                        aria-controls="pills-all-tab"
                        aria-selected="true"
                      >
                        All
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class={
                          tab === "Present" ? "nav-link active" : "nav-link"
                        }
                        onClick={() => {
                          setTab("Present");
                        }}
                        id="pills-present-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-present"
                        type="button"
                        role="tab"
                        aria-controls="pills-present"
                        aria-selected="true"
                      >
                        present
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class={
                          tab === "Absent" ? "nav-link active" : "nav-link"
                        }
                        onClick={() => {
                          setTab("Absent");
                        }}
                        id="pills-absent-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-absent"
                        type="button"
                        role="tab"
                        aria-controls="pills-absent"
                        aria-selected="false"
                      >
                        absent
                      </button>
                    </li>
                  </ul>

                  {register_for === "class" && (
                    <div className="d-flex gap-3">
                      <div className="form-group selectAreaCode">
                        <div className="input-container">
                          {/* <select
                            className="form-control pl-5 form-select timeSelectWidth"
                            name="areacode"
                            onChange={(e) => {
                              setTime(e.target.value);
                            }}
                            value={time}
                          >
                            <option value="">Select Time</option>

                            {availableTimeSlots?.map((item, i) => (
                              <option value={item} key={i}>
                                {moment(item, "hh:mm:ss").format("hh:mm a")}
                              </option>
                            ))}
                          </select>
                           */}

                          <Select
                            // isMulti
                            options={availableTimeSlots || undefined}
                            getOptionLabel={(option) =>
                              `${moment(option.start_time, "hh:mm:ss").format(
                                "hh:mm a"
                              )}-${moment(option?.end_time, "hh:mm:ss").format(
                                "hh:mm a"
                              )}`
                            }
                            styles={style}
                            getOptionValue={(option) => {
                              return option;
                            }}
                            className="pl-5 form-select timeSelectWidth"
                            value={time || undefined}
                            onChange={(val) => {
                              setTime(val);
                            }}
                          />
                          <img
                            src="assets/img/event-name.png"
                            className="input-img inputImage"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="form-group selectAreaCode">
                        <div className="input-container">
                          {/* <select
                            className="form-control pl-5 form-select timeSelectWidth"
                            name="areacode"
                            onChange={(e) => {
                              setDate(e.target.value);
                            }}
                            value={date}
                          >
                            <option value="">Select Date</option>

                            {availableDates?.map((item, i) => (
                              <option value={item} key={i}>
                                {moment(item).format("DD MMM YYYY")}
                              </option>
                            ))}
                          </select> */}
                          <Select
                            // isMulti
                            options={availableDates || undefined}
                            getOptionLabel={(option) =>
                              `${moment(option.date).format("DD MMM YYYY")}`
                            }
                            styles={style}
                            getOptionValue={(option) => option}
                            className="pl-5 form-select timeSelectWidth"
                            value={date || undefined}
                            onChange={(val) => {
                              // console.log(val, "Date change val");
                              setDate(val);
                            }}
                          />
                          <img
                            src="assets/img/event-name.png"
                            className="input-img inputImage"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div class="col-sm-12">
                  <div class="tab-content" id="pills-tab">
                    <div
                      class={
                        tab === "all"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="pills-all"
                      role="tabpanel"
                      aria-labelledby="pills-all-tab"
                    >
                      <div class="table-responsive text-center">
                        <table class="table storetable">
                          <thead class="text-center">
                            <tr>
                              <th scope="col">
                                full name{" "}
                                <BiSort
                                  size={20}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleSort("first_name")}
                                />
                              </th>
                              {/* <th scope="col">full address</th> */}
                              <th scope="col">membership</th>
                              <th scope="col">membership level</th>
                              <th scope="col">Actions</th>
                            </tr>
                            <tr class="border-0 bg-none invisible">
                              <td class="border-0 bg-none"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan={5}>
                                  <Loader />
                                </td>
                              </tr>
                            ) : data?.student_list?.pending_student?.length >
                              0 ? (
                              sortedData(
                                data?.student_list?.pending_student
                              )?.map((student, i) => (
                                <tr key={i}>
                                  <td
                                    onClick={() => {
                                      navigate(`/student/${student?.id}`);
                                    }}
                                    className="cursor-pointer"
                                  >
                                     <span>{student?.first_name} {student?.last_name}</span>
                                    {student?.wallet_amount < 0 && (
                                      <p className="text-danger font-weight-bold">
                                      There is debt!<b style={{ marginLeft: "10px" }}>{student?.wallet_amount}</b>
                                    </p>
                                    )}
                                  </td>
                                  {/* <td>
                                      {student?.parent_address
                                        ? `${
                                            student?.parent_address
                                              ?.property_number
                                          }, ${
                                            student?.parent_address
                                              ?.property_name
                                              ? `${student?.parent_address?.property_name}, `
                                              : ""
                                          } ${
                                            student?.parent_address
                                              ?.street_number
                                          }, ${
                                            student?.parent_address?.town
                                          }, ${
                                            student?.parent_address?.country
                                          }-${
                                            student?.parent_address?.postal_code
                                          }`
                                        : "NA"}
                                    </td> */}
                                  <td>
                                    {student?.membership_details
                                      ? "Active"
                                      : "Trail"}
                                  </td>
                                  <td>
                                    {student?.membership_details
                                      ?.membership_details?.name || "NA"}
                                  </td>
                                  <td>
                                    <button
                                      className="btn mx-1 attendanceButton"
                                      type="button"
                                      onClick={() => {
                                        StudentAttendance({
                                          dataa: student,
                                          status: "Present",
                                        });
                                      }}
                                      disabled={
                                        student?.attendane_status?.toLowerCase() ===
                                        "present"
                                      }
                                    >
                                      Mark Present
                                    </button>
                                    <button
                                      className="btn mx-1 attendanceButton"
                                      type="button"
                                      onClick={() => {
                                        StudentAttendance({
                                          dataa: student,
                                          status: "Absent",
                                        });
                                      }}
                                      disabled={
                                        student?.attendane_status?.toLowerCase() ===
                                        "absent"
                                      }
                                    >
                                      Mark Absent
                                    </button>
                                    {register_for === "class" && (
                                      <button
                                        className="btn mx-1 attendanceButton"
                                        type="button"
                                        disabled={
                                          student?.attendane_status
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          setChangeClassForStudentData({
                                            studentId: student?.id,
                                            student_type: student?.student_type,
                                            trialAccountStatus:
                                              student?.membership_details
                                                ? "3"
                                                : "1",
                                            time_slot_for:
                                              student?.student_type,

                                            adult_price: data?.adult_price,
                                            child_price: data?.child_price,
                                            teacherId:
                                              data?.assigned_teacher
                                                ?.teacher_details?.id,
                                            schoolId: data?.school_details?.id,
                                            old_booking_student_id:
                                              student?.old_booking_student_id,
                                            userId:
                                              student?.parent_address?.userId,
                                          });
                                          handleShow();
                                        }}
                                      >
                                        Change Class
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <td colSpan={6}>No student found.</td>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div
                      class={
                        tab === "Present"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="pills-present"
                      role="tabpanel"
                      aria-labelledby="pills-present-tab"
                    >
                      <div class="table-responsive text-center">
                        <table class="table storetable">
                          <thead class="text-center">
                            <tr>
                              <th scope="col">
                                full name{" "}
                                <BiSort
                                  size={20}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleSort("first_name")}
                                />
                              </th>
                              {/* <th scope="col">full address</th> */}
                              <th scope="col">membership</th>
                              <th scope="col">membership level</th>
                              <th scope="col">Actions</th>
                            </tr>
                            <tr class="border-0 bg-none invisible">
                              <td class="border-0 bg-none"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <td colSpan={5}>
                                <Loader />
                              </td>
                            ) : data?.student_list?.present_student?.length >
                              0 ? (
                              sortedData(
                                data?.student_list?.present_student
                              )?.map((student, i) => (
                                <tr key={i}>
                                  <td
                                    onClick={() => {
                                      navigate(`/student/${student?.id}`);
                                    }}
                                  >
                                     <span>{student?.first_name} {student?.last_name}</span>
                                    {student?.wallet_amount < 0 && (
                                      <p className="text-danger font-weight-bold">
                                      There is debt!<b style={{ marginLeft: "10px" }}>{student?.wallet_amount}</b>
                                    </p>
                                    )}
                                  </td>
                                  {/* <td>
                                      {student?.parent_address
                                        ? `${
                                            student?.parent_address
                                              ?.property_number
                                          }, ${
                                            student?.parent_address
                                              ?.property_name
                                              ? `${student?.parent_address?.property_name}, `
                                              : ""
                                          } ${
                                            student?.parent_address
                                              ?.street_number
                                          }, ${
                                            student?.parent_address?.town
                                          }, ${
                                            student?.parent_address?.country
                                          }-${
                                            student?.parent_address?.postal_code
                                          }`
                                        : "NA"}
                                    </td> */}
                                  <td>
                                    {student?.membership_details
                                      ? "Active"
                                      : "Trail"}
                                  </td>
                                  <td>
                                    {" "}
                                    {student?.membership_details
                                      ?.membership_details?.name || "NA"}
                                  </td>
                                  <td>
                                    <button
                                      className="btn mx-1 attendanceButton"
                                      type="button"
                                      onClick={() => {
                                        StudentAttendance({
                                          dataa: student,
                                          status: "Absent",
                                        });
                                      }}
                                    >
                                      Mark Absent
                                    </button>
                                    {register_for === "class" && (
                                      <button
                                        className="btn mx-1 attendanceButton"
                                        type="button"
                                        onClick={() => {
                                          setChangeClassForStudentData({
                                            studentId: student?.id,
                                            student_type: student?.student_type,
                                            trialAccountStatus:
                                              student?.membership_details
                                                ? "3"
                                                : "1",
                                            time_slot_for:
                                              student?.student_type,

                                            adult_price: data?.adult_price,
                                            child_price: data?.child_price,
                                            teacherId:
                                              data?.assigned_teacher
                                                ?.teacher_details?.id,
                                            schoolId: data?.school_details?.id,
                                            old_booking_student_id:
                                              student?.old_booking_student_id,
                                            userId:
                                              student?.parent_address?.userId,
                                          });
                                          handleShow();
                                        }}
                                      >
                                        Change Class
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <td colSpan={6}>No student found.</td>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div
                      class={
                        tab === "Absent"
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="pills-absent"
                      role="tabpanel"
                      aria-labelledby="pills-absent-tab"
                    >
                      <div class="table-responsive text-center">
                        <table class="table storetable">
                          <thead class="text-center">
                            <tr>
                              <th scope="col">
                                full name{" "}
                                <BiSort
                                  size={20}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleSort("first_name")}
                                />
                              </th>
                              {/* <th scope="col">full address</th> */}
                              <th scope="col">membership</th>
                              <th scope="col">membership level</th>
                              <th scope="col">Actions</th>
                            </tr>
                            <tr class="border-0 bg-none invisible">
                              <td class="border-0 bg-none"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <td colSpan={5}>
                                <Loader />
                              </td>
                            ) : data?.student_list?.absent_student?.length >
                              0 ? (
                              sortedData(
                                data?.student_list?.absent_student
                              )?.map((student, i) => (
                                <tr key={i}>
                                  <td
                                    onClick={() => {
                                      navigate(`/student/${student?.id}`);
                                    }}
                                  >
                                     <span>{student?.first_name} {student?.last_name}</span>
                                    {student?.wallet_amount < 0 && (
                                      <p className="text-danger font-weight-bold">
                                      There is debt!<b style={{ marginLeft: "10px" }}>{student?.wallet_amount}</b>
                                    </p>
                                    )}
                                  </td>
                                  {/* <td>
                                      {student?.parent_address
                                        ? `${
                                            student?.parent_address
                                              ?.property_number
                                          }, ${
                                            student?.parent_address
                                              ?.property_name
                                              ? `${student?.parent_address?.property_name}, `
                                              : ""
                                          } ${
                                            student?.parent_address
                                              ?.street_number
                                          }, ${
                                            student?.parent_address?.town
                                          }, ${
                                            student?.parent_address?.country
                                          }-${
                                            student?.parent_address?.postal_code
                                          }`
                                        : "NA"}
                                    </td> */}
                                  <td>
                                    {student?.membership_details
                                      ? "Active"
                                      : "Trail"}
                                  </td>
                                  <td>
                                    {" "}
                                    {student?.membership_details
                                      ?.membership_details?.name || "NA"}
                                  </td>
                                  <td>
                                    <button
                                      className="btn mx-1 attendanceButton"
                                      type="button"
                                      onClick={() => {
                                        StudentAttendance({
                                          dataa: student,
                                          status: "Present",
                                        });
                                      }}
                                    >
                                      Mark Present
                                    </button>
                                    <button
                                      className="btn mx-1 attendanceButton"
                                      type="button"
                                      onClick={() => {
                                        setChangeClassForStudentData({
                                          studentId: student?.id,
                                          student_type: student?.student_type,
                                          trialAccountStatus:
                                            student?.membership_details
                                              ? "3"
                                              : "1",

                                          time_slot_for: student?.student_type,

                                          adult_price: data?.adult_price,
                                          child_price: data?.child_price,
                                          teacherId:
                                            data?.assigned_teacher
                                              ?.teacher_details?.id,
                                          schoolId: data?.school_details?.id,
                                          old_booking_student_id:
                                            student?.old_booking_student_id,
                                          userId:
                                            student?.parent_address?.userId,
                                        });
                                        handleShow();
                                      }}
                                    >
                                      Change Class
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <td colSpan={6}>No student found.</td>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddNewStudent
          submitting={addStudentSubmitting}
          show={show}
          handleShow={toggle}
          availableDates={availableDates}
          handleAddStudentToRegister={(_student, _time, _date, _class_type) => {
            addStudentToRegister(_student, _time, _date, _class_type);
          }}
          availableTimeSlots={availableTimeSlots}
          register_for={register_for}
          //   students={
          //     register_for === "class"
          //       ? studentForClass
          //       : register_for === "event"
          //       ? studentForEvent
          //       : []
          //     studentLists || []
          //   }
          class_type={
            register_for === "class"
              ? data?.class_type
              : register_for === "event"
              ? data?.age_group
              : ""
          }
        />
        {changeClassForStudentData && (
          <ChangeStudentClassComponent
            submitting={changeClassSubmitting}
            show={showClass}
            handleShow={handleShow}
            class_type={changeClassForStudentData?.time_slot_for}
            id={id}
            changeClassesForStudentData={(
              val,
              dates,
              selectedClass,
              start_time,
              end_time,
              time_slot_id
            ) => {
              setChangeClassForStudentData((prev) => {
                return {
                  ...prev,
                  classId: val,
                };
              });

              handleChangeClass({
                ...changeClassForStudentData,
                classId: val,
                date: dates.date,
                start_time: dates?.start_time,
                end_time: dates?.end_time,
                time_slot_id: dates?.time_slot_id,
                total_amount:
                  changeClassForStudentData?.time_slot_for.toLowerCase() ===
                  "child"
                    ? selectedClass?.child_price
                    : changeClassForStudentData?.time_slot_for.toLowerCase() ===
                      "adult"
                    ? selectedClass?.adult_price
                    : 0,
              });
            }}
          />
        )}

        <Cancel
          submitting={cancelClassSubmitting}
          show={showCancelClassModal}
          handleShow={() => {
            setShowCancelClassModal(!showCancelClassModal);
          }}
          page={"Class"}
          handleCancel={handleCancelClass}
        />
      </article>
    );
  }
}

export default RegisterDetail;
